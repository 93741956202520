import Select from 'react-select'
import { createClient } from 'graphqurl'
import { useState } from 'react'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import styles from '../styles/Home.module.scss'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
// import requestIp from 'request-ip'
// import geoip from 'geoip-lite'

type Filter = {
	titles: [],
	categories: [],
	mrts: any[],
	cities: any[],
	jobTypes: []
}

const Jobs = ({ jobTitles, categories, mrts, latestJobs, companies, state }) => {
	companies = companies.filter((c) => {
		// only show employers with jobs
		return !([5066, 5056, 5376].includes(c.id)) && c.Jobs?.length !== 0
	}).slice(0, 8)
	// latestJobs = latestJobs.sort((a, b) => {
	// 	const tempA = dayjs(a.jobPostStart).valueOf()
	// 	const tempB = dayjs(b.jobPostStart).valueOf()
	// 	if (tempA < tempB) { return -1 }
	// 	else if (tempA > tempB) { return 1 }
	// 	return 0
	// }).reverse()
	const router = useRouter()

	const { t } = useTranslation()
	const [filters, setFilters] = useState<Filter>({
		titles: [],
		categories: [],
		mrts: [],
		cities: [],
		jobTypes: []
	})

	const options = [
		{ value: 'FT', label: t('job_descrip.ft') },
		{ value: 'PT', label: t('job_descrip.pt') },
		{ value: 'CONTRACT', label: t('job_descrip.con') }
	]
	const jobTitleOptions = jobTitles.map(ele => {
		return { value: ele.id, label: ele.name }
	})
	jobTitleOptions.push({ value: 9999, label: t('jobs.Others') })
	const categoriesOptions = categories.map(ele => {
		return { value: ele.id, label: ele.name }
	})
	const mrtOptions = mrts.map(ele => {
		return { value: ele.id, label: ele.name }
	})
	const otherOptions = [
		// { value: 'others', label: t('jobs.outside_southeast_region') },
		{ value: 'multiple', label: 'Islandwide' }
	]

	const groupedOptions = [
		{
			label: 'All MRT Location',
			options: otherOptions
		},
		{
			label: t('filter.mrt'),
			options: mrtOptions
		},
	]

	const citiesOptions = state.map(data => {
		return {
			'label': data.name,
			'options': data.Cities.map(city => {
				return {
					'value': city.id,
					'label': city.name
				}
			})
		}
	})

	const groupedCitiesOptions = [
		...citiesOptions,
	]

	const formatGroupLabel = data => (
		<div>
			<span style={{ color: '#EF6B08' }}>{data.label}</span>
		</div>
	)

	const onChange = (values, type) => {
		const mappedArr = values.map(value => value.value)

		setFilters({
			...filters,
			...(type === 'titles' && { titles: mappedArr }),
			...(type === 'categories' && { categories: mappedArr }),
			...(type === 'jobTypes' && { jobTypes: mappedArr })
		})
	}

	const onMRTChange = (values, selected) => {
		if (selected.action === 'clear') {
			setFilters({ ...filters, mrts: [] })
		} else if (selected.action === 'remove-value') {
			if (selected.removedValue.value === 'others' || selected.removedValue.value === 'multiple') {
				setFilters({ ...filters, mrts: [] })
			} else setFilters({ ...filters, mrts: values })
		} else if (selected.action !== 'clear' && selected.action !== 'remove-value' && selected.action !== 'pop-value' && (selected.option.value === 'others' || selected.option.value === 'multiple')) {
			const arr = selected.option.value === 'others' ? [{ value: 'others', label: t('jobs.outside_southeast_region') }] : [{ value: 'multiple', label: t('jobs.Islandwide') }]

			setFilters({ ...filters, mrts: arr })
		} else {
			const filteredArr = values.filter(value => (value.value !== 'others' && value.value !== 'multiple'))
			setFilters({ ...filters, mrts: filteredArr })
		}
	}

	const onCityChange = (values, selected) => {
		if (selected.action === 'clear') {
			setFilters({ ...filters, cities: [] })
		} else if (selected.action === 'remove-value') {
			if (selected.removedValue.value === 'others' || selected.removedValue.value === 'multiple') {
				setFilters({ ...filters, cities: [] })
			} else setFilters({ ...filters, cities: values })
		} else if (selected.action !== 'clear' && selected.action !== 'remove-value' && selected.action !== 'pop-value' && (selected.option.value === 'others' || selected.option.value === 'multiple')) {
			const arr = selected.option.value === 'others' ? [{ value: 'others', label: t('jobs.outside_southeast_region') }] : [{ value: 'multiple', label: t('jobs.Islandwide') }]

			setFilters({ ...filters, cities: arr })
		} else {
			const filteredArr = values.filter(value => (value.value !== 'others' && value.value !== 'multiple'))
			setFilters({ ...filters, cities: filteredArr })
		}
	}

	const slugify = (str) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')

		return str.toString().toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	}

	const getCountryText = () => {
		switch(router.locale) {
		case 'my':
			return 'Malaysia'
			break
		case 'sg':
			return 'Singapore'
			break
		default:
			return ''
		}
	}

	return (
		<Container>
			{/* <Row className='align-items-center'>
				<Col lg={6} md={12} sm={12} xs={12}>
					<br />
					<h2 className='fw-bold h3'>{t('job_list.welcome_to_findjobs')}{router.locale !== 'default' && ', ' + getCountryText()}</h2>
					<br />
				</Col>
			</Row> */}
			<Card className='mx-auto p-4 mt-3'>
				<h3 className='text-center fw-bold'>{t('root.search_jobs')}</h3>
				<br />
				<Row className='gy-3'>
					<Col md={6} sm={12} xs={12}>
						<Select
							id="job-select"
							instanceId="job-select"
							options={jobTitleOptions}
							isMulti closeMenuOnSelect={false}
							placeholder={t('jobs.All_Job_Categories')}
							onChange={values => onChange(values, 'titles')}
						// value={}
						/>
					</Col>
					<Col md={6} sm={12} xs={12}>
						<Select
							id="categories-select"
							instanceId="categories-select"
							options={categoriesOptions}
							isMulti closeMenuOnSelect={false}
							placeholder={t('jobs.All_Categories')}
							onChange={values => {
								onChange(values, 'categories')
							}}
						/>
					</Col>
					<Col md={6} sm={12} xs={12}>
						<Select
							id="job-type-select"
							instanceId="job-type-select"
							options={options}
							isMulti closeMenuOnSelect={false}
							placeholder={t('jobs.All_Job_Types')}
							onChange={values => onChange(values, 'jobTypes')}
						/>
					</Col>
					<Col md={6} sm={12} xs={12}>
						{
							router.locale === 'sg' ? (
								<Select
									id="locations-select"
									instanceId="locations-select"
									options={groupedOptions}
									formatGroupLabel={formatGroupLabel}
									isMulti closeMenuOnSelect={false}
									placeholder={t('jobs.All_Locations')}
									onChange={(values, selected) => onMRTChange(values, selected)}
									value={filters.mrts}
								/>
							) : router.locale === 'my' ? (
								<Select
									id="locations-select"
									instanceId="locations-select"
									options={groupedCitiesOptions}
									formatGroupLabel={formatGroupLabel}
									isMulti closeMenuOnSelect={false}
									placeholder={t('jobs.All_Locations')}
									onChange={(values, selected) => onCityChange(values, selected)}
									value={filters.cities}
								/>
							) : (
								<></>
							)
						}
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col md={6} sm={12} xs={12}>
						<div style={{ textAlign: 'right' }} className='mb-2'>
							<Link href={`/${router.locale}/browse-jobs`} passHref>
								<a>
									<Button
										variant='link'
										className='fw-bold'>{t('filter.view_all')}</Button>
								</a>
							</Link>
						</div>
					</Col>
					<Col md={6} sm={12} xs={12}>
						<Button
							variant='primary'
							className='w-100 text-white fw-bold'
							onClick={() => {
								const titles = filters.titles.join()
								const categories = filters.categories.join()
								const jobTypes = filters.jobTypes.join()
								// const locations = filters.mrts.join(ele => ele.value)
								const locations = filters.mrts.map(ele => ele.value).join()
								const city = filters.cities.map(ele => ele.value).join()
								// Generate the url

								const locationUrl = router.locale === 'sg' ? `mrt=${locations}&` : router.locale === 'my' ? `city=${city}&` : ''
								const url = `/${router.locale}/browse-jobs?occupation=${titles}&category=${categories}&jobType=${jobTypes}&${locationUrl}`
								router.push(url)
							}}
						>{t('search')}</Button>
					</Col>
				</Row>
			</Card>
			<hr style={{ backgroundColor: '#ddd' }} />

			<h3 className='text-center fw-bold mt-5 mb-4'>{t('jobs.latest_jobs')}</h3>

			<Row className={'gy-2'}>
				{latestJobs.map(job => {
					const salary = job.JobSalaries[0]
					return (
						<Col lg={4} md={6} sm={12} xs={12} key={job.id}>
							<Card style={{ minHeight: '310px' }}>
								<Card.Body className='pt-3'>
									{job.Company.avatar && <Image src={job.Company.avatar} alt='Company Logo' className='mt-3 mb-2' style={{ maxHeight: '50px' }} />}
									{!job.Company.avatar && <div style={{ paddingTop: '20px' }}><div style={{ border: '1px solid #eee', width: '50%', padding: '20px' }}>{job.Company.name}</div></div>}
									<div style={{ minHeight: '125px' }}>
										<p className={`mt-3 mb-0 fw-bold ${styles.twoLineEllipsis}`}>{job.title}</p>
										<p className={`mb-0 ${styles.twoLineEllipsis}`}>{job.Company.name}</p>
										{
											salary?.max || salary?.min ? (
												<p style={{ color: '#F88F1A' }}>
													{router.locale?.toUpperCase() === 'SG' ? '$' : router.locale?.toUpperCase() === 'MY' ? 'RM' : '$'} {salary?.max ? salary?.max : salary?.min} / {salary?.jobType === 'FT' ? t('root.month') : t('root.hour')}
												</p>
											) : (
												<></>
											)
										}
									</div>
									<Button
										className='text-white w-100'
										href={`/${router.locale}/job/${slugify(job.title)}-${job.id}`}
									>{t('jobs.view_job')}</Button>
								</Card.Body>
							</Card>
						</Col>
					)
				})}
			</Row>

			<Row className='justify-content-center'>
				<Col xs={12} md={4}>
					<Button
						variant='outline-primary mt-5'
						className='w-100 fw-bold'
						href={`/${router.locale}/browse-jobs`}
					>{t('jobs.view_more')}</Button>
				</Col>
			</Row>

			<hr className='my-5' />

			<h3 className='text-center fw-bold'>{t('jobs.these_employers_are_hiring')}</h3>

			<Row className={'gy-2'}>
				{companies.map(company => {
					return (
						<Col lg={3} md={6} sm={12} xs={12} key={company.id}>
							<a href={`/${router.locale}/company/jobs/${company.id}`} className='text-decoration-none text-black'>
								<Card style={{ minHeight: '200px' }} >
									<Card.Body className='d-flex flex-column justify-content-center text-center'>
										<div className='w-100'>
											{!company.avatar && <div style={{ border: '1px solid #eee', padding: '10px', borderRadius: '10px', color: 'gray' }}>{company.name}</div>}
											{company.avatar && <Image src={company.avatar} alt={company.name} style={{ objectFit: 'cover', maxHeight: '70px' }} />}
										</div>
										<p className='mt-3'>{company.name}</p>
									</Card.Body>
								</Card>
							</a>
						</Col>
					)
				})}
			</Row>

			<Row className='justify-content-center'>
				<Col xs={12} md={4}>
					<br />
					<Button
						variant='outline-primary'
						className='w-100 fw-bold'
						href={`/${router.locale}/browse-employers`}
					>{t('jobs.view_more')}</Button>
				</Col>
			</Row>

			<hr className='my-5' />
			<h3 className='text-center fw-bold mt-5 mb-4'>{t('jobs.Partner_with_us_as_an_Employer_now')}</h3>

			<div className='text-center mb-5'>
				<Row className='justify-content-center'>
					<Col xs={12} md={4}>
						<Button
							variant='primary'
							className='w-100 fw-bold text-white'
							href='https://dashboard.findjobs.asia/signup'
						>{t('sign_up.employer')}</Button>
					</Col>
				</Row>
				<br />
				<div>
					{t('jobs.Existing_employer')}{' '}
					<a
						href='https://dashboard.findjobs.asia'
						target='_blank' rel='noreferrer'
					>{t('login.login')}</a>
				</div>
			</div>

		</Container>
	)
}

export async function getServerSideProps({ locale }) {
	const client = createClient({
		endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string,
		headers: {
			'X-Hasura-Admin-Secret': process.env.HASURA_ADMIN_SECRET as string,
		}
	})

	const hasuraQuery = await client.query({
		query: `
		query ($date: timestamptz!, $countrySelected: String!) {
			# Job Titles
			Occupation(
				where: {id: {_neq: 9999}}
				order_by: {name: asc}
			) {
				id name nameChinese nameMalay imgUrl industry
			}

			Category(
				order_by: {name: asc}
			) {
				id name
			}

			MrtStation(
				order_by: {name: asc}
				distinct_on: name
			) {
				id name nameChinese
				# MrtLine { colour}
			}

			Company(where: {
				User: {
					UserPlatforms: {
						platform: {_eq: "REGULAR"}
					},
					country: {_eq: $countrySelected},
				},
				Jobs: {isAvailable: {_eq: true},
				JobPlatforms: {platform: {_eq: "REGULAR"}},
				jobPostStart: {_lte: $date},
				jobPostEnd: {_gte: $date}}
			},
			order_by: {name: asc}, limit: 20) {
				id
				name
				avatar
			}

			State {
				id
				name
				country
				Cities {
					id
					name
				}
			}
		}
		`,
		variables: {
			date: dayjs(),
			countrySelected: locale.toUpperCase() as string
		}
	})

	// Get latest jobs
	const res = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}v1/jobseeker/job?country=${locale?.toUpperCase()}&pageSize=6`)
	const jobsData = await res.json()
	const jobs = jobsData.rows

	const { data: { Occupation, Category, MrtStation, Company, State } } = hasuraQuery

	return {
		props: {
			...(await serverSideTranslations(locale, ['common', 'footer'])),
			jobTitles: Occupation,
			categories: Category,
			mrts: MrtStation,
			state: State,
			latestJobs: jobs,
			companies: Company,
		}
	}
}

export default Jobs
